import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { useAsyncSetState } from "use-async-setstate";

import Terms from "../portal/terms";
import PrivacyPolicy from "../portal/privacy-policy";

export default function Footer(props) {
  const [showTerm, setTerm] = useAsyncSetState(false);
  const [showPolicy, setPolicy] = useAsyncSetState(false);
  const dateNow = new Date();
  const year = dateNow && dateNow.getFullYear();
  return (<>
    {showTerm && <Terms onClose={() => setTerm(false)} />}
    {showPolicy && <PrivacyPolicy onClose={() => setPolicy(false)} />}
    <footer className="font-white footer-component">
      <Container>
        <Row>
          <Col xs="auto">
            <a href="/">
              {`© Iglu ${year}`}
            </a>
          </Col>
          <Col xs={"auto"} className="ml-auto">
            <a onClick={() => setTerm(true)}>
              {"Terms And Conditions"}
            </a>
            <span> | </span>
            <a href="/esafety" target="_blank" className="esafety-link">
              {"eSafety"}
            </a>
           </Col>
        </Row>
      </Container>
    </footer>
  </>);
}